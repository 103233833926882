<template>
  <table class="program-table">
    <thead>
      <tr>
        <th class="program-table__number">
          Dzień
        </th>
        <th class="program-table__date">
          Data
        </th>
        <th class="program-table__body">
          Program
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          1
        </td>
        <td>
          28.07.22
        </td>
        <td>
          • Zbiórka (Lotnisko Fryderyka Chopina Warszawie godzina 16:20 - hala odlotów Nowego Terminala/ przy słupie
          oznaczonym Meeting Point)<br/>
          • Przelot bezpośredni liniami LOT, godziny wylotu 18:20 – 19:45<br/>
          • Transfer do hotelu 5*<br/>
          • Kolacja w restauracji SESSIONS
        </td>
      </tr>
      <tr>
        <td>
          2
        </td>
        <td>
          29.07.22
        </td>
        <td>
          • Zwiedzanie Tuk-tukami + destylarnia
          przejazd na tor Hungaroring<br/>
          • Sesja treningowa I<br/>
          • Przerwa i lunch na Hungarorongu<br/>
          • Sesja treningowa II<br/>
          • Kolacja w restauracji
        </td>
      </tr>
      <tr>
        <td>
          3
        </td>
        <td>
          30.07.22
        </td>
        <td>
          • Rano wizyta w termach Szechenyi
          przejazd na tor Hungaroring<br/>
          • Sesja treningowa III<br/>
          • Lunch na Hungaroringu<br/>
          • Kwalifikacje<br/>
          • Prywatny rejs po Dunaju z kolacją i winem.
        </td>
      </tr>
      <tr>
        <td>
          4
        </td>
        <td>
          31.07.22
        </td>
        <td>
          • Wykwaterowanie z hotelu<br/>
          • Spacer po Budapeszcie - czas wolny<br/>
          • Lunch w restauracji w mieście<br/>
          • Transfer na tor<br/>
          • Główny wyścig<br/>
          • Transfer na lotnisko<br/>
          • Przelot do Warszawy bezpośredni LOTem, godziny odlotu 20:20-21:35
        </td>
      </tr>
    </tbody>
  </table>
  <div class="py-3 font-size-18">
    <strong>Dodatkowe informacje:</strong><br/>
    <ul>
      <li>
        Opiekun naszej grupy podczas pobytu w Budapeszcie: Tomasz Pomałecki tel. <a href="tel:48662002755"
                                                                                    class="text-white d-inline-block">+48
        662 002 755</a>
      </li>
      <li>
        Bagaż: ze względu na problemy logistyczne na lotniskach prosimy o spakowanie się do bagażu podręcznego
        (wymiarach 55x40x23 cm do 8 kg)
      </li>
      <li>
        Co warto dopakować: kąpielówki (ze względu na zaplanowany pobyt w termach Szechenyi)
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: "ProgramTable"
  }
</script>

<style scoped>

</style>
