<template>
  <footer class="footer">
    <div class="footer__logo">
      <a href="https://www.dell.com/pl-pl" target="_blank"><img src="/img/logo-dell.png" /></a>
    </div>
  </footer>
</template>

<script>
  export default {
    name: "Footer"
  }
</script>

<style scoped>

</style>
