<template>
  <section class="main__top" id="inauguracja">
    <div class="container-fluid container-fluid-stop">
      <div class="logo">
        <img src="/img/logo-dam-club.png" />
      </div>
      <div class="container-fluid-stop-narrow">
        <div class="text-center section__heading section__heading--large-top-margin section__heading--large-bottom-margin">
          <h2 class="bg-blue mb-5 p-3">
            Inauguracyjny wyjazd „DAM Club” na wyścig F1
            w&nbsp;Budapeszcie!
          </h2>
          <p>
            Już tylko dni dzielą nas od sportowych emocji najbardziej prestiżowego wyścigu samochodowego. Zapoznaj się z planem i zobacz co przygotowaliśmy.
          </p>
        </div>
        <div class="section__more-details section-more-details">
          <div class="text-center">
            <a href="#">
              <img src="/img/btn-more-details.png" />
            </a>
          </div>
          <div class="section-more-details__table">
            <program-table/>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import ProgramTable from "../components/ProgramTable";
  export default {
    name: "Inauguration",
    components: {ProgramTable}
  }
</script>

<style scoped>

</style>
