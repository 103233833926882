import {createApp} from 'vue'
import App from "./App.vue";
import router from "./router/router";
import {createPinia} from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import axios from "axios";
import mobileAppMenu from "./plugins/mobile-app-menu";
import VueEasyLightbox from "vue-easy-lightbox";

axios.defaults.baseURL = '/';

const app = createApp(App)

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

app.use(pinia)
app.use(router)
app.use(VueEasyLightbox)

app.use(mobileAppMenu)

app.mount('#app')
