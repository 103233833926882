<template>
  <div class="page page--home page-home">
    <header-component />
    <main class="main">
      <inauguration/>
      <voting/>
      <moments/>
      <about/>
    </main>
    <footer-component/>
  </div>
</template>

<script>
  import Header from "../components/Header";
  import Footer from "../components/Footer";
  import Inauguration from "../sections/Inauguration";
  import Voting from "../sections/Voting";
  import Moments from "../sections/Moments";
  import About from "../sections/About";

  export default {
    name: "Home",

    components: {
      About,
      Moments,
      Voting,
      Inauguration,
      'header-component': Header,
      'footer-component': Footer
    }
  }
</script>

<style scoped>

</style>
