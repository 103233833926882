<template>
  <div class="page page--login page-login">
    <header class="header-empty">

    </header>
    <main class="main">
      <div class="container-fluid container-fluid-stop">
        <div class="logo">
          <img src="/img/logo-dam-club.png" />
        </div>
        <transition name="fade" mode="out-in">
          <login-card v-if="!user.isEmailSent"/>
          <login-card-success v-else />
        </transition>
      </div>
    </main>
    <footer-component />
  </div>
</template>

<script>
  import Footer from "../components/Footer";
  import LoginCard from "../components/LoginCard";
  import {useUserStore} from "../store/user";
  import LoginCardSuccess from "../components/LoginCardSuccess";

  export default {
    name: "Login",

    setup() {
      const userStore = useUserStore()

      return {
        user: userStore
      }
    },

    components: {
      LoginCardSuccess,
      LoginCard,
      'footer-component': Footer
    }
  }
</script>

<style scoped>

</style>
