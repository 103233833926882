<template>
  <div class="login-card">
    <div class="login-card__body">
      <h2>
        Na podany adres email wysłaliśmy wiadomość z indywidualnym linkiem.
      </h2>
      <p>
        Sprawdź skrzynkę email.
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    name: "LoginCardSuccess"
  }
</script>

<style scoped>

</style>
