<template>
  <div class="navbar navbar-expand-tablet">
    <button class="btn navbar-toggler text-white ms-auto navigation__navbar-toggler"
            ref="toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar"
            aria-controls="navbar"
            aria-expanded="false"
            aria-label="Toggle navigation">
      <i class="fa-solid fa-bars"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbar" ref="navbar">
      <div class="navigation ">
        <ul class="navigation__list">
          <template v-for="item in nav">
            <li class="navigation__item">
              <a class="navigation__link" @click.prevent="scrollTo(item.scrollTo)" :href="`#${item.scrollTo}`">{{ item.name }}</a>
            </li>
            <li class="navigation__item navigation__item--spacer"></li>
          </template>
        </ul>
      </div>
    </div>

  </div>
</template>

<script>
  import {Collapse} from 'bootstrap'
  export default {
    name: "Navigation",

    data() {
      return {
        nav: [
          {
            name: 'Inauguracja',
            scrollTo: 'inauguracja'
          },
          {
            name: 'Głosuj',
            scrollTo: 'glosuj'
          },
          {
            name: 'Momenty',
            scrollTo: 'momenty',
          },
          {
            name: 'O kolektywie',
            scrollTo: 'o-kolektywie'
          }
        ]
      }
    },

    methods: {
      scrollTo(target) {
        const collapse = Collapse.getOrCreateInstance(this.$refs.navbar)
        collapse.hide()
        const offsetTop = document.getElementById(target).offsetTop
        window.scrollTo({
          top: offsetTop,
          behavior: 'smooth'
        })
        return (e) => {
          e.preventDefault()
        }
      }
    },

    mounted() {
      new Collapse(this.$refs.toggler)
    }

  }
</script>

<style scoped>

</style>
