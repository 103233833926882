<template>
  <section class="section section-moments main__moments" id="momenty">
    <div class="container-fluid">
      <div class="container-fluid-stop-narrow">
        <div class="text-center">
          <h2 class="bg-blue mb-5 p-3 d-inline-block">
            Momenty
          </h2>
          <p>
            Tutaj zobaczysz niektóre z niezwykłych momentów naszego kolektywu.
          </p>
        </div>
        <template v-for="gallery in data.galleries" v-if="!loading">
          <Gallery :gallery="gallery" />
        </template>
        <loading v-else />
      </div>
    </div>
  </section>
</template>

<script>
  import {ref} from "vue";
  import Gallery from "../components/Gallery";
  import {useDataStore} from "../store/data";
  import Loading from "../components/Loading";

  export default {
    name: "Moments",
    components: {Loading, Gallery},

    data() {
      return {
        loading: true
      }
    },

    setup() {
      const dataStore = useDataStore()


      return {
        data: dataStore
      }
    },

    methods: {
      async retrieveGalleriesHandler() {
        try {
          await this.data.retrieveGalleries()
          this.loading = false
        } catch (error) {

        }
      }
    },

    created() {
      this.retrieveGalleriesHandler()
    }
  }
</script>

<style scoped>

</style>
