<template>
  <section class="section section-voting main__voting" id="glosuj">
    <div class="container-fluid">
      <div class="container-fluid-stop-narrow">
        <div class="text-center">
          <h2 class="bg-blue mb-5 p-3 d-inline-block">
            Głosuj
          </h2>
          <p>
            DAM Club to przede wszystkim pasja i&nbsp;niezapomniane przeżycia.
            Na szlaku której przygody spotkamy się kolejny raz? Oddaj swój głos i&nbsp;zdecyduj!
          </p>
        </div>
        <div class="row justify-content-between py-5">
          <voting-card
              v-if="data.getChoices.length"
              v-for="choice in data.getChoices"
              :choice="choice"
              class="col-tablet-4 col-desktop-3"></voting-card>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import VotingCard from "../components/VotingCard";
  import {useDataStore} from "../store/data";

  export default {
    name: "Voting",
    components: {VotingCard},

    setup() {
      const dataStore = useDataStore()

      return {
        data: dataStore
      }
    },

    mounted() {
      this.data.retrieveChoices()
    }

  }
</script>

<style scoped>

</style>
