<template>
  <router-view v-slot="{Component}">
    <transition name="fade" @after-enter="onAfterEnter">
      <component :is="Component" :key="$route.fullPath"/>
    </transition>
  </router-view>
</template>

<script>
  export default {
    name: "App",

    methods: {
      onAfterEnter() {
        if (this.$route.name === 'home') this.$mobileAppMenu()
      }
    }
  }
</script>

<style scoped>
</style>
