<template>
  <div class="d-inline-block fa-3x">
    <i class="fa-solid fa-spinner fa-spin-pulse"></i>
  </div>
</template>

<script>
  export default {
    name: "Loading"
  }
</script>

<style scoped>

</style>
