<template>
  <div class="">
    <div class="gallery__title">
      {{ gallery.title }}
    </div>
    <div class="row g-1">
      <div class="col-6 col-tablet-3" v-for="(src, index) in gallery.thumbs" :key="index">
        <div class="gallery__image" @click="() => showImg(index)">
          <img :src="src"/>
        </div>
      </div>
    </div>
    <vue-easy-lightbox :visible="visibleRef" :imgs="imgs" :index="indexRef" @hide="onHide" :move-disabled="true">
      <template v-slot:toolbar="{ toolbarMethods }" >
        <div class="vel-toolbar" v-if="!disableToolbar">
          <div role="button" @click="toolbarMethods.zoomIn" aria-label="zoom in button" class="toolbar-btn toolbar-btn__zoomin">
            <svg class="vel-icon icon" aria-hidden="true">
              <use xlink:href="#icon-zoomin"></use>
            </svg>
          </div>
          <div role="button" @click="toolbarMethods.zoomOut" aria-label="zoom out button" class="toolbar-btn toolbar-btn__zoomout">
            <svg class="vel-icon icon" aria-hidden="true">
              <use xlink:href="#icon-zoomout"></use>
            </svg>
          </div>
        </div>
      </template>
    </vue-easy-lightbox>
  </div>
</template>

<script>
  import {ref} from "vue";

  export default {
    name: "Gallery",

    props: ['gallery'],

    data() {
      return {
        disableToolbar: true
      }
    },

    setup(props) {

      const visibleRef = ref(false)
      const indexRef = ref(0)
      const imgs = props.gallery.images
      const showImg = (index) => {
        indexRef.value = index
        visibleRef.value = true
      }
      const onHide = () => visibleRef.value = false
      return {
        visibleRef,
        indexRef,
        imgs,
        showImg,
        onHide
      }
    }
  }
</script>

<style scoped>

</style>
