<template>

  <div class="page page--login page-login">
    <header class="header-empty">

    </header>
    <main class="main">
      <div class="container-fluid container-fluid-stop">
        <div class="logo">
          <img src="/img/logo-dam-club.png"/>
        </div>
        <div class="text-center">
          <loading class=" mt-5" v-if="loading"/>
          <h2 class="mt-5" v-else>{{errorMessage}}</h2>
        </div>
      </div>
    </main>
    <footer-component/>
  </div>
</template>

<script>
  import Footer from "../components/Footer";
  import LoginCard from "../components/LoginCard";
  import {useUserStore} from "../store/user";
  import Loading from "../components/Loading";

  export default {
    name: "Verification",
    components: {
      Loading,
      LoginCard,
      'footer-component': Footer
    },

    setup() {
      const userStore = useUserStore()

      return {
        user: userStore
      }
    },

    data() {
      return {
        loading: true,
        errorMessage: ''
      }
    },

    methods: {
      async verificationHandler() {
        try {

          const response = await this.user.validateToken(this.$route.params.token)

          if (response.success) {
            this.user.$patch((state) => {
              state.token = this.$route.params.token
            })
            this.$router.push('/home')
          } else {
            this.errorMessage = response.msg
          }

          this.loading = false

        } catch (error) {
          console.log(error)
        }
      }
    },

    mounted() {
      this.verificationHandler()
    }
  }
</script>

<style scoped>

</style>
