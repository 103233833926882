<template>
  <div class="voting-card">
    <div class="row h-100 flex-tablet-column">
      <div class="col-4 col-tablet-12 ">
        <div class="voting-card__image">
          <img :src="choice.image" />
        </div>
      </div>
      <div class="col-8 col-tablet-12 voting-card__body">
        <div class="voting-card__title">
          {{ choice.title }}
        </div>
        <div class="voting-card__description">
          {{ choice.description }}
        </div>
        <div class="voting-card__submit" v-if="user.getVoteId === null">
          <transition name="fade" mode="out-in">
            <button @click="voteHandler" :disabled="user.isVoteSending" v-if="!loading">
              <img src="/img/btn-vote.png"/>
            </button>
            <loading v-else />
          </transition>
        </div>
        <div class="" v-if="user.getVoteId === choice.id">
          <strong>{{ user.getVotedMessage }}</strong>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {useUserStore} from "../store/user";
  import Loading from "./Loading";

  export default {
    name: "VotingCard",
    components: {Loading},
    props: ['choice'],

    data() {
      return {
        loading: false
      }
    },

    setup() {
      const userStore = useUserStore()

      return {
        user: userStore
      }
    },

    methods: {
      async voteHandler() {
        this.loading = true
        this.user.$patch((state) => {
          state.voteSending = true
        })
        try {
          await this.user.vote(this.choice.id)
        } catch (error) {
          console.log(error)
        }
      }
    }
  }
</script>

<style scoped>

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.1s ease;
  }

  .fade-enter-to,
  .fade-leave-from {
    opacity: 1;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
