import {defineStore} from "pinia"
import axios from "axios";

export const useUserStore = defineStore('user', {
  state: () => ({
    email: null,
    token: null,
    emailSent: false,
    emailSentSuccess: null,
    voteId: null,
    voteSending: false,
    votedMessage: ''
  }),
  actions: {
    async sendEmail(email) {
      const form = new FormData()
      form.append('email', email)
      const response = await axios.post('api/login',
        form,
        {
          headers: {"Content-Type": "multipart/form-data"},
        })
      return response.data
    },

    async validateToken(token) {
      const form = new FormData()
      form.append('hash', token)
      const response = await axios.post('api/verify-hash',
        form,
        {
          headers: {"Content-Type": "multipart/form-data"},
        })
      this.voteId = response.data.vote.id
      this.votedMessage = response.data.vote.msg
      return response.data
    },

    async vote(id) {
      const form = new FormData()
      form.append('hash', this.token)
      form.append('vote', id)
      const response = await axios.post('api/vote',
        form,
        {
          headers: {"Content-Type": "multipart/form-data"},
        })
      this.votedMessage = response.data.msg
      this.voteId = id
    }
  },
  getters: {
    isAuthenticated: (state) => !!state.token,
    isEmailSent: (state) => state.emailSent,
    isVoteSending: (state) => state.voteSending,
    getVoteId: (state) => state.voteId,
    getToken: (state) => state.token,
    getVotedMessage: state => state.votedMessage
  },
  persist: {
    key: 'user',
    paths: ['email', 'token', 'voteId', 'votedMessage']
  }
})
