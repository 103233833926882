import {createRouter, createWebHistory} from "vue-router/dist/vue-router";
import Home from "../views/Home";
import Login from "../views/Login";
import Verification from "../views/Verification";
import {useUserStore} from "../store/user";

const routes = [
  {
    path: '/',
    component: Login,
    meta: {guest: true}
  },
  {
    name: 'home',
    path: '/home',
    component: Home,
    meta: {
      requiresAuth: true
    },
  },
  {
    name: 'home-token',
    path: '/home/:token',
    component: Verification
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {top: 0}
  }
})

router.beforeEach((to, from, next) => {
  const userStore = useUserStore()
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (userStore.isAuthenticated) {
      next()
      return
    }
    next('/')
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  const userStore = useUserStore()
  if (to.matched.some((record) => record.meta.guest)) {
    if (userStore.isAuthenticated) {
      next(`/home/${userStore.getToken}`);
      return;
    }
    next();
  } else {
    next();
  }
});

export default router
