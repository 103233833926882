import {defineStore} from "pinia";
import axios from "axios";

export const useDataStore = defineStore('data', {
  state: () => ({
    choices: [],
    galleries: []
  }),

  actions: {
    async retrieveChoices() {
      const response = await axios.get('/choices.json')

      this.choices = response.data
    },

    async retrieveGalleries() {
      const response = await axios.get('/galleries.json')

      let galleries = response.data

      galleries = galleries.map(gallery => {
        const thumbs = gallery.images.map(item => item.thumb)
        const images = gallery.images.map(item => item.image)
        return {
          thumbs: thumbs,
          images: images,
          title: gallery.title,
          id: gallery.id
        }
      })

      this.galleries = galleries
    }
  },

  getters: {
    getChoices: (state) => state.choices,
  }
})
