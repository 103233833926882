<template>
  <header class="header">
    <div class="container-fluid-stop container-fluid">
      <navigation/>
    </div>
  </header>
</template>

<script>
  import {gsap} from "gsap";
  import ScrollTrigger from 'gsap/ScrollTrigger'
  import Navigation from "./Navigation";

  gsap.registerPlugin(ScrollTrigger)

  export default {
    name: "Header",
    components: {Navigation},

    data() {
      return {
        animation: null,
        pushed: false
      }
    },

    updated() {
      console.log('updated')
     /* ScrollTrigger.create({
        trigger: ".main",
        start: "top top",
        onUpdate: self => {

          console.log('onUpdate')
          document.querySelector('.header').classList.toggle('header--pull', self.direction === 1)
        },
        onToggle: self => {
          console.log('onToggle')
          document.querySelector('.header').classList.toggle('header--after-scroll', self.isActive)
        }
      })*/
    }
  }
</script>

<style scoped>

</style>
