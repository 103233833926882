<template>
  <div class="login-card">
    <div class="login-card__body">
      <form @submit.prevent="submitHandler">
        <h2>
          Witaj w ekskluzywnym kolektywie Dell.
        </h2>
        <p>
          Wpisz swojego maila i wejdź do strefy wrażeń.
        </p>
        <div class="login-card__input-group">
          <label class="login-card__input-label">
            Email
          </label>
          <input class="login-card__input" type="text" inputmode="email" v-model="email" @input="inputHandler"/>
          <div class="input-errors" v-for="error of v$.email.$errors" :key="error.$uid">
            <div class="error-msg">{{ error.$message }}</div>
          </div>
          <div class="input-errors" v-if="showError">
            <div class="error-msg">{{ error }}</div>
          </div>
        </div>
        <div class="text-center">
          <transition name="fade" mode="out-in">
            <button type="submit"
                    v-if="!loading"
                    class="login-card__input-submit"><img src="/img/btn-submit.png"/></button>
            <loading v-else />
          </transition>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import useVuelidate from '@vuelidate/core'
  import {required, email, helpers} from '@vuelidate/validators'
  import {useUserStore} from "../store/user";
  import Loading from "./Loading";

  export default {
    name: "LoginCard",
    components: {Loading},
    setup() {

      const userStore = useUserStore()

      return {
        v$: useVuelidate(),
        user: userStore
      }
    },

    data() {
      return {
        email: '',
        showError: false,
        error: '',
        loading: false
      }
    },

    validations() {
      return {
        email: {
          required: helpers.withMessage('Pole jest wymagane.', required),
          email: helpers.withMessage('Wprowadź prawidłowy adres email.', email)
        }
      }
    },

    methods: {
      inputHandler() {
        this.showError = false
      },

      async submitHandler() {

        this.loading = true

        const isFormCorrect = await this.v$.$validate()

        if (!isFormCorrect) {

          this.loading = false
          return
        }

        try {

          const response = await this.user.sendEmail(this.email)
          this.user.$patch((state) => {
            state.emailSent = response.success
          })

          this.showError = false

          if (!response.success) {
            this.error = response.msg
            this.showError = true
          }

        } catch (error) {
          this.showError = true
          this.error = (error.response.data.error)
        }

        this.loading = false

      }
    }
  }
</script>

<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.1s ease;
  }

  .fade-enter-to,
  .fade-leave-from {
    opacity: 1;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
