<template>
  <section class="section section-about main__about" id="o-kolektywie">
    <div class="container-fluid">
      <div class="container-fluid-stop-narrow">
        <div class="text-center">
          <h2 class="bg-blue mb-5 p-3 d-inline-block">
            O kolektywie
          </h2>
          <p>
            <strong>Discover amazing moments with Dell</strong><br/>
            to prestiżowy kolektyw ludzi wymagających,<br/>
            którzy oczekują od życia najwyższej jakości i&nbsp;wyszukanego smaku.
            <br/><br/>
            Wspólnie dzielimy niezwykłe przeżycia, wyjątkowe momenty
            i&nbsp;nieprzeciętne pasje.
            <br/><br/>
            Doceniamy, że jesteś z&nbsp;nami!
            <br/><br/>
            <strong>Team Dell</strong>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: "About"
  }
</script>

<style scoped>

</style>
