import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger)

export default {
  install: (app, options) => {
    app.config.globalProperties.$mobileAppMenu = (key) => {
      ScrollTrigger.create({
        trigger: '.main',
        start: "top top",
        onUpdate: self => {
          document.querySelector('.header').classList.toggle('header--pull', self.direction === 1)
        },
        onToggle: self => {
          document.querySelector('.header').classList.toggle('header--after-scroll', self.isActive)
        }
      })
    }
  }
}
